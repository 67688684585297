import Vue from 'vue'
// Buefy
import Buefy from 'buefy'
// VeeValidate
import * as VeeValidate from 'vee-validate'
// Global Events
import App from './App.vue'
import store from './store.js'
import router from './router'
import fullscreen from 'vue-fullscreen'
import 'buefy/dist/buefy.css'
import './assets/wakasoft.css'
Vue.use(Buefy)
// VeeValidate
Vue.use(VeeValidate)

// fullScreen
Vue.use(fullscreen)

Vue.config.productionTip = false
// Vue.config.errorHandler = (err, vm, info) => {
//   console.log('ERREUR GLOBALE ' + err.message)
// }
new Vue({
  router: router,
  store: store,
  render: h => h(App)
}).$mount('#app')
