/* parameters = {key1:value1, key2:value2,...} */
import { NotificationProgrammatic as Notification } from 'buefy'
async function httpGet (url, parameters) {
  const oUrl = new URL(url)
  console.log(parameters)
  Object.keys(parameters).forEach(key => oUrl.searchParams.append(key, parameters[key]))
  console.log(oUrl)
  const response = await fetch(oUrl, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
      'Content-Type': 'application/json'
    }
  })
  if (response.ok) {
    return response.json()
  } else if (response.status === 400) {
    const vm = await response.json()
    throw new Error(vm.data)
  }
}
async function httpPost (url, body) {
  var vm = null
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
      'Content-Type': 'application/json'
    },
    body: body
  })
  if (response.ok) {
    console.log(response)
    vm = await response.json()
  } else {
    const vm = await response.json()
    throw new Error(vm.data)
  }
  return vm
}
async function httpPostForm (url, body) {
  var vm = null
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
      'Content-Type': 'application/json'
    },
    body: body
  })
  if (response.ok) {
    vm = await response.json()
  } else if (response.status === 400) {
    const vm = await response.json()
    throw new Error(vm.data)
  }
  return vm
}

function inputPassword (e) {
  const s = String.fromCharCode(e.keyCode)
  const regex = /^[A-Z]+$/
  var bool = regex.test(s)
  if (!bool) {
    e.preventDefault()
  }
}

function inputMail (e) {
  const s = String.fromCharCode(e.keyCode)
  const regex = /^[a-z0-9@@._-]+$/
  var bool = regex.test(s)
  if (!bool) {
    e.preventDefault()
  }
}

function inputCardCode (e) {
  const s = String.fromCharCode(e.keyCode)
  const regex = /^[A-Z0-9]+$/
  var bool = regex.test(s)
  if (!bool) {
    e.preventDefault()
  }
}
function inputPhone (e) {
  const s = String.fromCharCode(e.keyCode)
  const regex = /^[\s0-9+]+$/
  var bool = regex.test(s)
  if (!bool) {
    e.preventDefault()
  }
}

function inputName (e) {
  const s = String.fromCharCode(e.keyCode)
  const regex = /^([^(){}<>/;`%]+)$/
  var bool = regex.test(s)
  if (!bool) {
    e.preventDefault()
  }
}
function help (s) {
  var message = ''
  switch (s) {
    case 'cardCode':
      message = 'saisissez ici le N° de la carte anonyme'
      break
    case 'subscriberCode':
      message = 'saisissez ici votre N° d\'adhérent'
      break
    case 'odlSubscriberCode':
      message = 'votre ancien N° d\'adhérent si vous en avez un '
      break
    default:
      break
  }
  Notification.open({
    message: message,
    position: 'is-bottom',
    type: 'is-primary',
    hasIcon: true
  })
}
export { httpGet, httpPost, httpPostForm, inputCardCode, inputMail, inputName, inputPassword, inputPhone, help }
