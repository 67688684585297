import Vue from 'vue'
import Vuex from 'vuex'
import * as signalR from '@aspnet/signalr'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
    subscriber: null,
    notification: {
      message: '',
      isActive: false,
      isSuccess: false
    },
    messages: [],
    step: 1,
    finalMessages: []
  },
  getters: {
    getSubscriber (state) {
      return state.subscriber
    },
    getStep (state) {
      return state.step
    },
    getNotification (state) {
      return state.notification
    },
    getMessages (state) {
      return state.messages
    },
    getfinalMessages (state) {
      return state.messages
    }
  },
  mutations: {
    SET_NOTIFICATION (state, newValue) {
      state.notification = newValue
    },
    SET_SUBSCRIBER (state, newValue) {
      state.subscriber = newValue
    },
    SET_STEP (state, newValue) {
      state.step = newValue
    },
    SET_FINAL_MESSAGE (state, newValue) {
      state.finalMessages = newValue
    }
  },
  actions: {
    setSubscriber: ({
      commit
    }, newValue) => {
      commit('SET_SUBSCRIBER', newValue)
    },
    setStep: ({
      commit
    }, newValue) => {
      commit('SET_STEP', newValue)
    },
    setNotification: ({
      commit
    }, newValue) => {
      commit('SET_NOTIFICATION', newValue)
    },
    setfinalMessages: ({
      commit
    }, newValue) => {
      commit('SET_FINAL_MESSAGE', newValue)
    },
    initialize: ({
      commit,
      state
    }) => {}
  },
  initialize: async ({
    commit,
    state
  }) => {
    console.log('initialisation sur la connexion ' + state.API_BASE_URL)
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(state.API_BASE_URL + '/signalr-data-hub', {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(signalR.LogLevel.Information)
      .build()
    connection.start()
    // raffraichissement total de la production
    connection.on('ReceiveCurrentProduction', function (p) {
      console.log('mise à jour production')
      commit('SET_CURRENT_PRODUCTION', p)
    })
  }
})
