<template>
  <div id="app">
    <div class="waka-content">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  computed: {
    environment () {
      return this.$store.getters.getEnvironment
    },
    classEnv () {
      return this.environment === 'TEST' ? 'is-danger' : 'is-light'
    }
  },
  async created () {
    // try {
    //   await this.$store.dispatch('initialize')
    // } catch (err) {
    //   this.message = err
    //   this.showDismissibleAlert = 4
    // }
    window.onerror = function (msg, url, line, col, error) {
      this.javascriptNotification = {
        message: error.message,
        isActive: true,
        isSuccess: false
      }
    }
  },
  methods: {}
}
</script>
