<template>
  <section>
    <div v-if="loginViewModel != null">
      <ValidationObserver ref="observer">
        <section class="section" slot-scope="{ validate }">
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required" name="mail" v-slot="{ valid, errors }">
                <b-field label="N° adhérent" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="enteredLogin" placeholder="N° adhérent / mail" autocomplete="off"
                    v-on:keypress.native="inputMail" v-on:keypress="inputMail">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required|regex:^([A-Z]+)" name="Password" v-slot="{ valid, errors }">
                <b-field
                  label="Code d'activation présent sur la carte Inter C.E.A.(caractères acceptés : les lettres majuscules)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="loginViewModel.password" v-on:keypress="inputPassword"
                    v-on:keypress.native="inputPassword" type="password" password-reveal>
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <div class="columns">
                <div class="column is-2">
                  <b-button class="mr-4" variant="success" @click="validate().then(submit)">Entrer</b-button>
                </div>
                <div class="column is-2">
                  <b-button variant="warning" @click="reset">Annuler</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <WakaNotification :notification="notification" />
            </div>
          </div>
        </section>
      </ValidationObserver>
    </div>
  </section>
</template>
<script>
import { httpPost, httpGet, inputMail, inputPassword } from '../wakasoft.js'
import WakaNotification from '@/components/WakaNotification.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email, min, length, regex } from 'vee-validate/dist/rules'
import Vue from 'vue'
extend('email', {
  ...email,
  message: 'le mail est invalide'
})
extend('length', {
  ...length,
  message: 'la longueur est invalide'
})
extend('min', {
  ...min,
  message: 'la longueur est invalide'
})
extend('regex', {
  ...regex,
  message: 'caractères non autorisés'
}
)
// Override the default message.
extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
})
export default {
  name: 'AuthentificationLoginSubscriber',
  components: {
    WakaNotification,
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    subscriber: {
      get () {
        return this.$store.state.subscriber
      },
      set (newValue) {
        return this.$store.dispatch('setSubscriber', newValue)
      }
    },
    messages: {
      get () {
        return this.$store.state.messages
      },
      set (newValue) {
        return this.$store.dispatch('setMessages', newValue)
      }
    },
    step: {
      get () {
        return this.$store.state.step
      },
      set (newValue) {
        return this.$store.dispatch('setStep', newValue)
      }
    },
    minDate () {
      return new Date(new Date().getFullYear() - 80, 1, 1)
    }
  },
  props: {
    defaultLogin: String
  },
  data () {
    return {
      loginViewModel: null,
      notification: { message: '', isActive: false, isSuccess: false },
      vueInstance: null,
      enteredLogin: ''
    }
  },
  watch: {
    // whenever question changes, this function will run
    enteredLogin (newLogin, oldLogin) {
      if (newLogin.includes('@')) {
        this.loginViewModel.email = newLogin
        this.loginViewModel.subscriberCode = ''
      } else {
        this.loginViewModel.email = ''
        this.loginViewModel.subscriberCode = newLogin
      }
    }
  },
  methods: {
    inputPassword: function (e) {
      return inputPassword(e)
    },
    inputMail: function (e) {
      return inputMail
    },
    async submit (valid) {
      // validation
      try {
        if (valid === false) {
          this.notification = { message: 'la saisie est incomplète', isSucess: false, isActive: true }
        } else {
          var url = this.$store.state.API_BASE_URL + '/login/authenticate-with-subscriber-card'
          var body = JSON.stringify(this.loginViewModel)
          this.loginViewModel = await httpPost(url, body)
          this.messages.push('authentification réussie')
          url = this.$store.state.API_BASE_URL + '/subscriber/'
          const subscriber = await httpGet(url, { subscriberId: this.loginViewModel.sageId })
          this.subscriber = subscriber
          this.step = 2
        }
      } catch (err) {
        console.log(err)
        this.notification = { message: err.message, isSucess: false, isActive: true }
      }
    },
    reset () {
      this.loginViewModel = { cardCode: '', name: '', birthDate: new Date(), firstName: '', subscriberCode: '', code: '', email: '' }
    }
  },
  async mounted () {
    var currentUrl = window.location
    const arr = currentUrl.href.split('=')
    var login = arr.length === 2 ? arr[1].split('#')[0] : ''
    this.reset()
    this.vueInstance = Vue
    this.enteredLogin = login
  }
}
</script>
