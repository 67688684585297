<template>
  <div>
    <div class="columns is-mobile">
      <div class="column is-half is-offset-one-quarter ">
        <img src="../assets/logo.jpg" alt="logo Intercea 2" width="200px" height="200px" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-offset-one-quarter is-half">
        <div>
          <ul id="example-1">
            <li v-for="item in messages" :key="item">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="isLoading === false" class="columns">
      <div class="column is-half is-offset-one-quarter">
        <section class="hero is-primary" v-if="isInMaintenance === true">
          <div class="hero-body">
            <p class="title">
              Maintenance
            </p>
            <p class="subtitle">
              l'activation des cartes est en maintenance pour la <span
                v-on:click="isInMaintenance = false">journée.</span>
            </p>
          </div>
        </section>
        <section>
          <div v-if="isInMaintenance === false">
            <div v-if="step === 1">
              <div>
                <div class="hero is-primary">
                  <div class="hero-body">
                    <p>{{ authenticationTitle }}
                      <a href="#" v-on:click="isAnonymCardAuthentication = !isAnonymCardAuthentication">ici</a>
                    </p>
                  </div>
                </div>
                <div v-if="isAnonymCardAuthentication === true">
                  <authentification-login-card :defaultLogin=login />
                </div>
                <div v-else>
                  <authentification-login-subscriber :defaultLogin=login />
                </div>
              </div>
            </div>
            <div v-if="step === 2">
              <subscriber />
            </div>
            <div v-if="step === 3">
              <div v-for="s in this.finalMessages" :key="s">
                <div class="columns is-mobile">
                  <div class="column is-full">
                    <h2>{{ s }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>
<script>
import AuthentificationLoginCard from '@/components/authentification-login-card.vue'
import AuthentificationLoginSubscriber from '@/components/authentification-login-subscriber.vue'
import Subscriber from '@/components/Subscriber.vue'
import { httpGet } from '../wakasoft'
export default {
  name: 'Main',
  components: {
    AuthentificationLoginCard, AuthentificationLoginSubscriber, Subscriber
  },
  data () {
    return {
      login: '',
      isAnonymCardAuthentication: true,
      isInMaintenance: false,
      isLoading: true
    }
  },
  computed: {
    authenticationTitle () {
      var msg = ''
      if (this.isAnonymCardAuthentication === true) {
        msg = 'Vous activez une carte anonyme, pour les carte adhérents classiques, c\'est par '
      } else {
        msg = 'Vous activez une carte adhérent, pour les carte anonymes, c\'est par '
      }
      return msg
    },
    notification: {
      get () {
        return this.$store.state.notification
      },
      set (newValue) {
        return this.$store.dispatch('setNotification', newValue)
      }
    },
    messages: {
      get () {
        return this.$store.state.messages
      },
      set (newValue) {
        return this.$store.dispatch('setMessages', newValue)
      }
    },
    finalMessages: {
      get () {
        return this.$store.state.finalMessages
      },
      set (newValue) {
        return this.$store.dispatch('setfinalMessages', newValue)
      }
    },
    step: {
      get () {
        return this.$store.state.step
      },
      set (newValue) {
        return this.$store.dispatch('setStep', newValue)
      }
    }
  },
  mounted: async function () {
    try {
      const url = this.$store.state.API_BASE_URL + '/is-in-maintenance'
      console.log(url)
      this.isInMaintenance = await httpGet(url, {})
    } catch (err) {
      console.log(err)
      this.notification = { message: err.message, isSucess: false, isActive: true }
    }
    var currentUrl = window.location
    console.log(currentUrl)
    const arr = currentUrl.href.split('=')
    console.log(arr.length)
    this.login = arr.length === 2 ? arr[1].split('#')[0] : 'CA'
    this.isAnonymCardAuthentication = this.login.length >= 2 && this.login.substring(0, 2) === 'CA'
    this.isLoading = false
  }
}
</script>
