<template>
  <section>
    <div v-if="subscriber !== null">
      <ValidationObserver ref="observer">
        <section class="section" slot-scope="{ validate }">
          <div class="columns">
            <div class="column is-half">
              <b-field label="N° Carte" type="is-success" message="" description="toto">
                <b-input disabled="true" v-model="subscriber.cardCode" readonly></b-input>
              </b-field>
            </div>
            <div v-if="subscriber.subscriberId !== 0" class="column  is-half">
              <b-field label="N° adhérent" type="is-success">
                <b-input v-model="subscriber.sageCode" readonly>
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-all">
              <ValidationProvider rules="required|min:2" name="Nom" v-slot="{ valid, errors }">
                <b-field label="Nom (caractères acceptés : les lettres majuscules et l'espace)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input :disabled="inputDisabled" v-model="subscriber.name" v-on:keypress.native="inputName"
                    v-on:keypress="inputName" placeholder="Nom" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <!-- PRENOM-->
            <div class="column is-all">
              <ValidationProvider rules="required|min:2" name="Prénom" v-slot="{ valid, errors }">
                <b-field label="Prénom (caractères acceptés : les lettres majuscules et l'espace)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input :disabled="inputDisabled" v-model="subscriber.firstName" v-on:keypress.native="inputName"
                    v-on:keypress="inputName" placeholder="Prénom" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <!-- DATE -->
          <div class="columns">
            <div class="column is-all">
              <ValidationProvider rules="required" name="Date" v-slot="{ valid, errors }">
                <b-field label="Date de naissance" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors">
                  <b-datepicker v-model="javascriptBirthDate" placeholder="Date de naissance" :min-date="minDate"
                    :max-date="maxDate">
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-all">
              <h2>Adresse</h2>
            </div>
          </div>
          <div class="columns">
            <div class="column is-7">
              <ValidationProvider rules="required" name="Rue" v-slot="{ valid, errors }">
                <b-field label="Rue" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="subscriber.address" v-on:keypress.native="inputName" v-on:keypress="inputName"
                    placeholder="Rue" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-5">
              <ValidationProvider name="Complement" v-slot="{ valid, errors }">
                <b-field label="Complément" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="subscriber.complement" v-on:keypress.native="inputName" v-on:keypress="inputName"
                    placeholder="Complément" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-2 ">
              <ValidationProvider rules="required" name="CodePostal" v-slot="{ valid, errors }">
                <b-field label="Code postal" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="subscriber.postalCode" v-on:keypress.native="inputName" v-on:keypress="inputName"
                    placeholder="Code postal" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-10">
              <ValidationProvider rules="required" name="Ville" v-slot="{ valid, errors }">
                <b-field label="Ville" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="subscriber.city" v-on:keypress.native="inputName" v-on:keypress="inputName"
                    placeholder="Ville" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <h2>Communication</h2>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half">
              <ValidationProvider rules="required|email|regex:^([a-z0-9@@._-]+)$" name="mail" v-slot="{ valid, errors }">
                <b-field label="Mail" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="subscriber.email" v-on:keypress.native="inputMail" v-on:keypress="inputMail"
                    placeholder="mail" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="column is-half">
              <!-- Téléphone -->
              <ValidationProvider rules="regex:^([0-9\s]+)$" name="phone" v-slot="{ valid, errors }">
                <b-field label="Téléphone (caractères acceptés [0-9], '+' et ' ')"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="subscriber.phoneNumber" v-on:keypress.native="inputPhone" v-on:keypress="inputPhone"
                    placeholder="Téléphone" autocomplete="off">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full ">
              <WakaNotification :notification="notification" />
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <div class="columns">
                <div class="column">
                  <b-button class="mr-4" variant="success" @click="validate().then(submit)">Entrer</b-button>
                  <b-button variant="warning" @click="reset">Annuler</b-button>
                  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ValidationObserver>
    </div>
  </section>
</template>
<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email, regex, length } from 'vee-validate/dist/rules'
import { httpPost, inputName, inputPhone, inputMail } from '../wakasoft.js'
import WakaNotification from '@/components/WakaNotification.vue'
extend('email', {
  ...email,
  message: 'le mail est invalide'
})
extend('regex', {
  ...regex,
  message: 'caractères non autorisés'
})
extend('length', {
  ...length,
  message: 'longuer invalide'
})
// Override the default message.
extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
})
export default {
  name: 'Subscriber',
  components: {
    ValidationObserver,
    ValidationProvider,
    WakaNotification
  },
  computed: {
    messages: {
      get () {
        return this.$store.state.messages
      },
      set (newValue) {
        return this.$store.dispatch('setMessages', newValue)
      }
    },
    javascriptBirthDate: {
      get () {
        return this.subscriber.birthDate === null ? null : new Date(this.subscriber.birthDate)
      },
      set (newValue) {
        this.subscriber.birthDate = newValue.toISOString()
      }
    },
    minDate () {
      return new Date(new Date().getFullYear() - 80, 1, 1)
    },
    maxDate () {
      const today = new Date()
      return new Date(today.getFullYear() - 17, today.getMonth(), today.getDate())
    },
    subscriber: {
      get () {
        return this.$store.state.subscriber
      },
      set (newValue) {
        return this.$store.dispatch('setSubscriber', newValue)
      }
    },
    finalMessages: {
      get () {
        return this.$store.state.finalMessages
      },
      set (newValue) {
        this.$store.dispatch('setfinalMessages', newValue)
      }
    },
    step: {
      get () {
        return this.$store.state.step
      },
      set (newValue) {
        return this.$store.dispatch('setStep', newValue)
      }
    }
  },
  data () {
    return {
      notification: { message: '', isActive: false, isSuccess: false },
      isLoading: false,
      inputDisable: false
    }
  },
  methods: {
    async submit (valid) {
      this.isLoading = true
      if (valid === false) {
        this.notification = { message: 'la saisie est incomplète', isSucess: false, isActive: true }
        this.isLoading = false
      } else {
        try {
          this.step = 3
          this.finalMessages.push('Création/Mise à jour de votre compte')
          var url = this.$store.state.API_BASE_URL + '/subscriber/update'
          var body = JSON.stringify(this.subscriber)
          var responseSubscriber = await httpPost(url, body)
          this.finalMessages.push('Votre numéro d\'adhérent est le ' + responseSubscriber.sageCode)
          this.finalMessages.push('Mise à jour de votre compte sur intercea.fr....')
          // url = this.$store.state.API_BASE_URL + '/subscriber/update-Prestashop'
          // await httpGet(url, { subscriberode: responseSubscriber.sageCode })
          this.finalMessages.push('Votre compte sera actif dans quelques minutes')
        } catch (err) {
          this.finalMessages.push(err.message)
        } finally {
          this.isLoading = false
        }
      }
    },
    reset () {
      this.subscriber = this.$store.state.subscriber
    },
    inputName: function (e) {
      return inputName(e)
    },
    inputMail: function (e) {
      return inputMail(e)
    },
    inputPhone: function (e) {
      return inputPhone(e)
    }
  },
  async mounted () {
    try {
      this.subscriber = this.$store.state.subscriber
      if (this.subscriber.cardCode === null || this.subscriber.cardCode === '') {
        this.inputDisabled = !((this.subscriber.name === '' || this.subscriber.lastname === ''))
      }
    } catch (err) {
      this.message = err
      this.showDismissibleAlert = 4
    }
  }
}
</script>
