<template>
  <b-notification v-if="notification !== null" auto-close :type=type v-model="notification.isActive"
    aria-close-label="Fermer" :duration=notification.duration :progressBar=notification.progressBar>
    {{ notification.message }}
  </b-notification>
</template>
<script>
export default {
  name: 'WakaNotification',
  props: {
    notification: { message: '', isActive: false, isSuccess: false, duration: 10000, progressBar: true }
  },
  computed: {
    type () {
      return this.notification === null ? '' : this.notification.isSuccess ? 'is-success' : 'is-danger'
    }
  }
}
</script>
