<template>
  <section>
    <div v-if="loginViewModel != null">
      <ValidationObserver ref="observer">
        <section class="section" slot-scope="{ validate }">
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required|regex:^([A-Z0-9]+)" name="CardCode" v-slot="{ valid, errors }">
                <b-field label="N° Carte (caractères acceptés : les lettres majuscules et les chiffres)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="loginViewModel.cardCode" v-on:keypress.native="inputCardCode" maxlength="30"
                    v-on:focusout.native="scroll"></b-input>
                  <p class="control">
                    <b-button type="is-primary" icon-left="help" v-on:click="help('cardCode')" />
                  </p>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required|regex:^([A-Z]+)" name="Password" v-slot="{ valid, errors }">
                <b-field label="Code d'activation de la carte (caractères acceptés : les lettres majuscules)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="loginViewModel.password" v-on:keypress="inputPassword" v-on:focusout.native="scroll"
                    v-on:keypress.native="inputPassword" type="password" password-reveal>
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required|min:2" name="name" v-slot="{ valid, errors }">
                <b-field label="Nom (caractères acceptés : les lettres majuscules et l'espace)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="loginViewModel.name" v-on:keypress="inputName" v-on:focusout.native="scroll"
                    v-on:keypress.native="inputName">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required|min:2" name="firstName" v-slot="{ valid, errors }">
                <b-field label="Prénom (caractères acceptés : les lettres majuscules et l'espace)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="loginViewModel.firstName" v-on:keypress.native="inputName" v-on:keypress="inputName"
                    v-on:focusout.native="scroll">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required" name="birthDate" v-slot="{ valid, errors }">
                <b-field label="Date de naissance" :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors">
                  <b-datepicker locale='fr-FR' v-model="loginViewModel.birthDate" placeholder="Date de naissance"
                    :max-date="maxDate" :min-date="minDate" v-on:focusout.native="scroll">
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="required|email" name="mail" v-slot="{ valid, errors }">
                <b-field label="Mail (caractères acceptés : les lettres minuscules, les chiffres et les caractères .-_@)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="loginViewModel.email" placeholder="mail" autocomplete="off"
                    v-on:keypress.native="inputMail" v-on:keypress="inputMail" v-on:focusout.native="scroll">
                  </b-input>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <ValidationProvider rules="length:7|regex:^([0-9]{7})$" name="subscriberCode" v-slot="{ valid, errors }">
                <b-field label="N° adhérent (à renseigner pour les détenteurs d'une carte les années précéentes)"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                  <b-input v-model="loginViewModel.SubscriberCode">
                  </b-input>
                  <p class="control">
                    <b-button type="is-primary" icon-left="help" v-on:click="help('oldSubscriberCode')" />
                  </p>
                </b-field>
              </ValidationProvider>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-full">
              <div class="columns">
                <div class="column">
                  <b-button class="mr-4" variant="success" @click="validate().then(submit)">Entrer</b-button>
                </div>
                <div class="column">
                  <b-button variant="warning" @click="reset">Annuler</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-full">
              <WakaNotification :notification="notification" />
              <b-notification type="is-danger" v-model="isNotificationActivatedCard" aria-close-label="Fermer">
                <p>La carte anonyme CAxxxxxx que vous avez saisie a déjà été activée.
                  Pour vous connecter à notre site, merci d'utiliser le
                  numéro adhérent qui vous a été envoyé par mail au moment de la première activation
                  de votre carte 2024 (sous le format 0XXXXXX) et en mot de passe (si vous ne l'avez pas modifié)
                  le code d'activation présent sur votre carte physique ou dématérialisée.
                  En cas d'oublis de vos identifiants, vous pouvez saisir votre adresse mail sur la page <a
                    href="https://www.intercea.fr/recuperation-mot-de-passe">
                    mot de passe oublié</a></p>
                <p>Notre équipe est à votre service pour répondre à vos questions si besoin</p>
              </b-notification>
            </div>
          </div>
        </section>
      </ValidationObserver>
    </div>
  </section>
</template>
<script>
import { httpPost, httpGet, inputCardCode, inputMail, inputName, inputPassword, help } from '../wakasoft.js'
import WakaNotification from '@/components/WakaNotification.vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required, email, min, length, regex } from 'vee-validate/dist/rules'
import Vue from 'vue'
extend('email', {
  ...email,
  message: 'le mail est invalide'
})
extend('length', {
  ...length,
  message: 'la longueur est invalide'
})
extend('min', {
  ...min,
  message: 'la longueur est invalide'
})
extend('regex', {
  ...regex,
  message: 'caractères non autorisés'
}
)
// Override the default message.
extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
})
export default {
  name: 'AuthentificationLoginCard',
  components: {
    WakaNotification,
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    subscriber: {
      get () {
        return this.$store.state.subscriber
      },
      set (newValue) {
        return this.$store.dispatch('setSubscriber', newValue)
      }
    },
    messages: {
      get () {
        return this.$store.state.messages
      },
      set (newValue) {
        return this.$store.dispatch('setMessages', newValue)
      }
    },
    step: {
      get () {
        return this.$store.state.step
      },
      set (newValue) {
        return this.$store.dispatch('setStep', newValue)
      }
    },
    minDate () {
      return new Date(new Date().getFullYear() - 80, 1, 1)
    },
    maxDate () {
      var d = new Date()
      return new Date(d.getFullYear() - 18, d.getMonth(), d.getDay())
    }
  },
  props: {
    defaultLogin: String
  },
  data () {
    return {
      loginViewModel: null,
      notification: { message: '', isActive: false, isSuccess: false },
      vueInstance: null,
      isNotificationActivatedCard: false

    }
  },
  methods: {
    help (s) {
      help(s)
    },
    scroll () {
      window.scrollBy(0, 100)
    },
    inputName (e) {
      return inputName(e)
    },
    inputCardCode (e) {
      return inputCardCode
    },
    inputMail (e) {
      return inputMail(e)
    },
    inputPassword (e) {
      return inputPassword(e)
    },
    async submit (valid) {
      // validation
      try {
        if (valid === false) {
          this.notification = { message: 'la saisie est incomplète', isSucess: false, isActive: true }
        } else {
          var url = this.$store.state.API_BASE_URL + '/login/authenticate-with-anonym-card'
          var body = JSON.stringify(this.loginViewModel)
          await httpPost(url, body)
          url = this.$store.state.API_BASE_URL + '/login/exists'
          body = JSON.stringify(this.loginViewModel)
          const sageId = await httpPost(url, body)
          if (sageId === 0) {
            this.messages.push('aucune correspondance trouvée, création d\'un nouvel adhérent')
            url = this.$store.state.API_BASE_URL + '/subscriber/'
            this.subscriber = {
              cardCode: this.loginViewModel.cardCode,
              name: this.loginViewModel.name,
              birthDate: this.loginViewModel.birthDate,
              firstName: this.loginViewModel.firstName,
              email: this.loginViewModel.email,
              sageId: 0,
              code: ''
            }
          } else {
            window.scrollTo(0, 0)
            url = this.$store.state.API_BASE_URL + '/subscriber/'
            const subscriber = await httpGet(url, { subscriberId: sageId })
            subscriber.birthDate = this.loginViewModel.birthDate
            subscriber.cardCode = this.loginViewModel.cardCode
            console.log(subscriber)
            this.messages.push('correspondance trouvée avec l\'adhérent ' + subscriber.sageCode)
            this.subscriber = subscriber
          }
          this.step = 2
        }
      } catch (err) {
        console.log(err)
        this.notification = { message: err.message, isSucess: false, isActive: true }
        if (err.message.indexOf('activée') !== -1) {
          this.isNotificationActivatedCard = true
        }
      }
    },
    reset () {
      this.loginViewModel = { cardCode: '', name: '', birthDate: null, firstName: '', subscriberCode: '', code: '', email: '' }
    }
  },
  async mounted () {
    this.reset()
    this.loginViewModel.cardCode = this.defaultLogin
    this.vueInstance = Vue
  }
}
</script>
